@font-face {
    font-family: "Prime";
    src: local("Courier Prime.ttf"),
      url("../Fonts/Courier\ Prime.ttf") format("truetype");
  }
  
  @font-face {
      font-family: "Prime italic";
      src: local("Courier Prime Italic.ttf"),
        url("../Fonts/Courier\ Prime\ Italic.ttf") format("truetype");
    }
  
    @font-face {
      font-family: "Prime Bold";
      src: local("Courier Prime Bold.ttf"),
        url("../Fonts/Courier\ Prime\ Bold.ttf") format("truetype");
    }
  
  * {
    font-family: "Prime";
  }
  .containerImgSophiePresta {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background-color: #B7B7A4;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .sophiePresta {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .sophiePresta {
    filter: opacity(50%);
    -webkit-filter: opacity(60%);
  }

  .textSophiePresta{
    position: relative;
    overflow:hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;

}

.divSophiePresta {
    display:inline-block;
    overflow:hidden;
    white-space:wrap;
    font-size: 30px;
    margin-bottom: 30px;
    color: #fcf3ed;
    text-align: center;
  }
  
  .divSophiePrestaSecond { 
    /* font-family: "Prime Bold"; */
    font-size: 20px;
    text-align: center;

    background-color: #fcf3ed;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap : 30px;
  }


  @keyframes showup {
    0% {opacity:0;}
    20% {opacity:0,5;}
    80% {opacity:0,5;}
    100% {opacity:1;}
}

@keyframes reveal {
    0% {opacity:0;}
    20% {opacity:0,3;}
    80% {opacity:0,5;}
    100% {opacity:1;}
}

.profilecontentframePresta {
    margin: auto;
    background: #fcf3ed;
    /* box-shadow: 20px 20px 41px #f4d8c4, -20px -20px 41px #fcf3ed; */
    /* width: 50%; */
    /* height: 100vh; */
    background-color: #fcf3ed;
    /* padding: 10px; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;
    flex-wrap: wrap;
    /* gap : 30px; */
    padding-top: 30px;
  }

  .profilecontentframeHome{
  
  
    background-color: #fcf3ed;
    /* padding: 10px; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;
    flex-wrap: wrap;
    gap : 30px;
    padding-top: 30px;
  }

  

  .profilecontentframePresta h4, h3{
    color: #c5a28a;
  }

  .btnSophiePresta {
    padding: 1.5em 4em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #CB997E;
    background-color: #B7B7A4;
    border: none;
    border-radius: 15px;
    /* box-shadow: 0px 8px 15px rgba(203, 135, 83, 0.1); */
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    margin-left: 20px;
    font-family: "Prime Bold";
  }

  .btnSophiePresta:hover {
    background-color: #DDBEA9;
    box-shadow: 0px 15px 20px rgba(251, 207, 174, 0.4);
    color: #fff;
    transform: translateY(-3px);
  }
  
  .btnSophiePresta:active {
    transform: translateY(-1px);
  }



  .btnTypeSeance:hover{
    background-color: #f9dfcf;
    box-shadow: 0px 15px 20px rgba(251, 207, 174, 0.4);
    color: #fff;
    transform: translateY(-3px);
  }

  .btnTypeSeance:active {
    transform: translateY(-1px);
  }
  


  .btnTypeSeanceAdulte:hover{
    background-color: #c6835f;
    box-shadow: 0px 15px 20px rgba(251, 207, 174, 0.4);
    color: #fff;
    transform: translateY(-3px);
  }

  .btnTypeSeanceAdulte:active {
    transform: translateY(-1px);
  }




  @media screen and (min-width: 600px) {
    .containerTypeSeance{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        gap:30px;
      }
      .imgBtnTypeSeance{
        width: 200px;
        height: 200px;
      }
      .imgBtnTypeSeanceAdulte{
        width: 200px;
        height: 130px;
      }
      .btnTypeSeance{
        width: 250px;
        height: 250px;
        color: #c5a28a;
        background-color: #fcf3ed;
        border: none;
        border-radius: 150px;
        box-shadow: 0px 8px 15px rgba(203, 135, 83, 0.1);
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
      }
      .btnTypeSeanceAdulte{
        width: 250px;
        height: 250px;
        color: #CB997E;
        background-color: #CB997E;
        border: none;
        border-radius: 80px;
        box-shadow: 0px 8px 15px rgba(205, 150, 109, 0.1);
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
      }
      #text{
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 20px;
        color: #f9dfcf;
        transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%); 
      }
      .profilecontentOverLay {
        margin: auto;
        box-shadow: 20px 20px 41px #cfad9a, -20px -20px 41px rgb(229, 203, 186);
        border-radius: 35px;
        width: 90%;
        height: 20%;
        background-color: #c6835f;
        padding: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
      }
  }

  @media screen and (max-width: 600px) {
    .containerTypeSeance{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        /* margin-top: 10%; */
        gap:30px;
        /* margin-bottom: 20px; */
      }
      .imgBtnTypeSeance{
        width: 140px;
        height: 100px;
      }
      .imgBtnTypeSeanceAdulte{
        width: 100px;
        height: 80px;
      }
      .btnTypeSeance{
        width: 150px;
        height: 150px;
        color: #c5a28a;
        background-color: #fcf3ed;
        border: none;
        border-radius: 150px;
        box-shadow: 0px 8px 15px rgba(203, 135, 83, 0.1);
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
      }
      .btnTypeSeanceAdulte{
        width: 150px;
        height: 150px;
        color: #CB997E;
        background-color: #CB997E;
        border: none;
        border-radius: 80px;
        box-shadow: 0px 8px 15px rgba(205, 150, 109, 0.1);
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
      }
      #text{
        position: absolute;
        top: 50%;
        left: 40%;
        font-size: 20px;
        color: #f9dfcf;
        transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);   
      }
      .profilecontentOverLay {
        margin: auto;
        box-shadow: 20px 20px 41px #cfad9a, -20px -20px 41px rgb(229, 203, 186);
        border-radius: 35px;
        width: 100%;
        height: 20%;
        background-color: #c6835f;
        padding: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
      }
  }



  #overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
  }

