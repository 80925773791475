@font-face {
  font-family: "Prime";
  src: local("Courier Prime.ttf"),
    url("./Fonts/Courier\ Prime.ttf") format("truetype");
}

@font-face {
    font-family: "Prime italic";
    src: local("Courier Prime Italic.ttf"),
      url("./Fonts/Courier\ Prime\ Italic.ttf") format("truetype");
  }

  @font-face {
    font-family: "Prime Bold";
    src: local("Courier Prime Bold.ttf"),
      url("./Fonts/Courier\ Prime\ Bold.ttf") format("truetype");
  }

* {
  font-family: "Prime";
}

@media screen and (min-width: 600px) {
    .containerImgSophieDesk {
        width: 100%;
        height: 400px;
        overflow: hidden;
        position: relative;
      }
      .textSophieDesk{
        position: relative;
        overflow:hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
    }
    .divSophieDesk { 
        /* font-family: "Prime Bold"; */
        font-size: 30px;
        margin-bottom: 60px;
        animation: showup 5s 1;
      }
      .btnTypeSeanceHome{
        background-color: #B7B7A4;
        /* box-shadow: 0px 15px 20px rgba(251, 207, 174, 0.4); */
        width: 250px;
        height: 250px;
        color: #c5a28a;
        border: none;
        border-radius: 150px;
        box-shadow: 0px 8px 15px rgba(203, 135, 83, 0.1);
    }
  }

  @media screen and (max-width: 600px) {
    .containerImgSophieDesk {
        width: 100%;
        height: 300px;
        overflow: hidden;
        position: relative;
      }
      .textSophieDesk{
        position: relative;
        overflow:hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
    }
    .divSophieDesk { 
        /* font-family: "Prime Bold"; */
        font-size: 27px;
        margin: 10px 30px 20px 30px;
        animation: showup 5s 1;
      }
      .btnTypeSeanceHome{
        background-color: #B7B7A4;
        /* box-shadow: 0px 15px 20px rgba(251, 207, 174, 0.4); */
        width: 150px;
        height: 150px;
        color: #c5a28a;
        border: none;
        border-radius: 150px;
        box-shadow: 0px 8px 15px rgba(203, 135, 83, 0.1);
    }
  }
  


.sophieDesk {
  width: 100%;
  height: 100%;
  position: absolute;
}


.sophieDesk {
  filter: opacity(50%);
  -webkit-filter: opacity(60%);
}

/* .sophieDesk:hover {
  filter: opacity(100%);
  -webkit-filter: opacity(100%);
} */



.divSophieDesk,.divSophieDeskSecond {
    display:inline-block;
    overflow:hidden;
    white-space:wrap;
    text-align: center;
  }
  


  .divSophieDesk span{ 
    /* font-family: "Prime Bold"; */
    font-size: 33px;
    margin-bottom: 60px;
    color: #c5a28a;
  }

  
  .divSophieDeskSecond {
    width:100%;
    height: 100vh;
    text-align: center;
    animation: reveal 3s 1;
  }

  .intro{
    animation: showup 5s 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }

  .divider{
    animation: showup 5s 1;
  }

  .divSophieDeskSecond span{
    font-family: "Prime italic";
    font-size: 12px;
  }

  
  @keyframes showup {
      0% {opacity:0;}
      20% {opacity:0,5;}
      80% {opacity:0,5;}
      100% {opacity:1;}
  }
  
  @keyframes reveal {
      0% {opacity:0;}
      20% {opacity:0;}
      80% {opacity:0;}
      100% {opacity:1;}
  }

  .btnDown {
    padding: 5px 6px;
    opacity: 90%;
    background-color: #fcf3ed;
    border: none;
    border-radius: 100px;
    box-shadow: 0px 8px 15px rgba(251, 207, 174, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
  }
  
  .btnDown:hover {
    background-color: #DDBEA9;
    box-shadow: 0px 15px 20px rgba(251, 207, 174, 0.4);
    color: #fff;
    transform: translateY(-7px);
  }
  
  .btnDown:active {
    transform: translateY(-1px);
  }

  .containerBtnDown{
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items:flex-end;
    margin-top: 100px;
  }

  .sophiePicture{
    width: 100px;
    border-radius: 60px;
  }

  /* ul{
    list-style-image: url('./Assets/icons8-lotus-50.png')
  } */

