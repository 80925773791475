footer {
    background-color: #fcf3ed;
    color: #000000;
    font-size: 14px;
    padding-top: 60px;
    padding-left: 0px;
    padding-bottom: 10px;
    padding-right: 0px;
    text-align: -webkit-center;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 30px;
  }
  
  .footer-section {
    width: 30%;
    margin-bottom: 40px;
  }
  
  .footer-section h2 {
    margin-bottom: 20px;
  }
  
  .footer-section p,
  .footer-section span {
    font-size: 14px;
  }
  
  .footer-section ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .footer-section li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .footer-section li i {
    margin-right: 10px;
  }
  
  .footer-section li a {
    color: #fff;
  }
  
  .footer-bottom {
    background-color: #0c1c24;
    padding: 10px 0;
    text-align: center;
  }
  
  .footer-bottom p {
    margin: 0;
  }

  .list-unstyled{
    list-style: none;
  }
  .instagram-icon {
    color: #405de6; /* Couleur principale d'Instagram */
    background: -webkit-linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d); /* Dégradé de couleurs d'Instagram */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .icone{
color: #c5a28a;
  }

  a{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: #c5a28a;
    gap: 10px;
    text-decoration: none;
  }

  @media screen and (min-width: 600px) {
  .footer-icons{
    display: flex;
    justify-content: space-around;
  
  }
  .tilte_contacter{
    text-align: center;
    color: #c5a28a;
    margin-bottom: 3%;
  }
}

  @media screen and (max-width: 600px) {
  .footer-icons{
  gap: 30px;
  display: grid;}
  .tilte_contacter{
    text-align: center;
    color: #c5a28a;
    margin-bottom: 10%;
  }
  }