
@media screen and (max-width: 600px) {
    .therapieBackground {
        width: 100%;
        height: 300px;
        position: absolute;
        /* filter: opacity(50%);
        -webkit-filter: opacity(60%); */
      }
    
      .containerTherapieTitle{
        width: 100%;
        height: 300px;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
}

@media screen and (min-width: 600px) {
    .therapieBackground {
        width: 100%;
        height: 500px;
        position: absolute;
        /* filter: opacity(50%);
        -webkit-filter: opacity(60%); */
      }
    
      .containerTherapieTitle{
        width: 100%;
        height: 350px;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
}


