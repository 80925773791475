@font-face {
    font-family: "Prime Bold";
    src: local("Courier Prime Bold.ttf"),
      url("../Fonts/Courier\ Prime\ Bold.ttf") format("truetype");
  }



@media screen and (min-width: 600px) {
    .navBar {
        width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      /* padding: 10px; */
    }
    .btnRDV {
        padding: 1.3em 1.3em;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        font-weight: 500;
        color: #c5a28a;
        background-color: #fcf3ed;
        border: none;
        border-radius: 15px;
        /* box-shadow: 0px 8px 15px rgba(203, 135, 83, 0.1); */
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
        margin-left: 20px;
        font-family: "Prime Bold";
      }
      
      .btnRDV:hover {
        background-color: #DDBEA9;
        box-shadow: 0px 15px 20px rgba(251, 207, 174, 0.4);
        color: #fff;
        transform: translateY(-7px);
      }
      
      .btnRDV:active {
        transform: translateY(-1px);
      }

      .logIn{
        margin-right: 20px;
    }
    .btnMoreInfo {
        padding: 1.3em 1.3em;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        font-weight: 500;
        color: #fff;
        background-color: #DDBEA9;
        box-shadow: 0px 15px 20px rgba(251, 207, 174, 0.4);
        border: none;
        border-radius: 15px;
        /* box-shadow: 0px 8px 15px rgba(203, 135, 83, 0.1); */
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
        margin-left: 20px;
        font-family: "Prime Bold";
        margin: 10px;
      }
    
  }

  @media screen and (max-width: 600px) {
    .navBar {
        width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      /* padding: 10px; */
    }
    .btnRDV {
        padding: 1.3em 1.3em;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        font-weight: 500;
        color: #fff;
        background-color: #DDBEA9;
        box-shadow: 0px 15px 20px rgba(251, 207, 174, 0.4);
        border: none;
        border-radius: 15px;
        /* box-shadow: 0px 8px 15px rgba(203, 135, 83, 0.1); */
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
        margin-left: 20px;
        font-family: "Prime Bold";
        margin: 10px;
      }
      .btnMoreInfo{
        padding: 1em 1em;
        font-size: 12px;
        /* text-transform: uppercase; */
        letter-spacing: 2.5px;
        font-weight: 400;
        color: #fff;
        background-color: #DDBEA9;
        box-shadow: 0px 15px 20px rgba(251, 207, 174, 0.4);
        border: none;
        border-radius: 15px;
        /* box-shadow: 0px 8px 15px rgba(203, 135, 83, 0.1); */
        transition: all 0.3s ease 0s;
        cursor: pointer;
        outline: none;
        margin-left: 20px;
        font-family: "Prime Bold";
        margin: 10px;
        width: 140px;
     
      }
  }

.linkNavBar {
    text-decoration: none;
    color:#626753;
    font-size: 15px;
}

.linkNavBar:hover {
    color: #DDBEA9;
  }

.link{
    display: flex;
    gap: 45px;
    text-align: center;
    padding: 10px;
}

.navBar{
    display: flex;
    flex-direction: column;
    background-color: #fcf3ed;
}

.buttonNavBar{
    border-radius: 10px;
    color: white;
    background-color: #626753;
}


.logoNavBar{
    width: 100%;
    margin: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
   

}


.btnRDVmobile{
    padding: 0.8em 0.8em;
    font-size: 8px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 500;
    color: #c5a28a;
    background-color: #fcf3ed;
    border: none;
    border-radius: 15px;
    /* box-shadow: 0px 8px 15px rgba(203, 135, 83, 0.1); */
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    margin-left: 10px;
    font-family: "Prime Bold";
}
  .logIn:hover {
    background-color: #DDBEA9;
    box-shadow: 0px 15px 20px rgba(251, 207, 174, 0.4);
    color: #fff;
    transform: translateY(-7px);
    border-radius: 50px;
  }

 

nav{
    width: 100%;
    margin: 0 auto;
    background-color: #fcf3ed;
    position: sticky;
    top: 0px;
}

nav ul{
    list-style-type: none;
    display: flex;
}

nav ul li{
    flex: 1 1 auto;
    text-align: center;
    position: relative;
}

nav a{
    display: block;
    text-decoration: none;
    color: black;
    border-bottom: 2px solid transparent;

}

nav a:hover{
    color: #DDBEA9;
    border-bottom: 2px solid #DDBEA9;
}

.sous{
    display: none;
    box-shadow: 0px 1px 2px #CCC;
    background-color: #fcf3ed;
    position: sticky;
    width: 100%;
    z-index: 1000;
}
nav > ul li:hover .sous{
    display: flex;
    flex-flow: column wrap;
}
.sous li{
    flex: 1 1 auto;
    text-align: left;
}
.sous a{
    padding: 10px;
    border-bottom: none;
}
.sous a:hover{
    border-bottom: none;
    background-color: RGBa(200,200,200,0.1);
}
.deroulant > a::after{
    /* content:" ▼"; */
    font-size: 12px;
}

.navBarMobile{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap : 10px;
    padding-right: 10px;
    margin-top: 10px;
}