@font-face {
    font-family: "Prime";
    src: local("Courier Prime.ttf"),
      url("../Fonts/Courier\ Prime.ttf") format("truetype");
  }
  
  @font-face {
      font-family: "Prime italic";
      src: local("Courier Prime Italic.ttf"),
        url("../Fonts/Courier\ Prime\ Italic.ttf") format("truetype");
    }
  
    @font-face {
      font-family: "Prime Bold";
      src: local("Courier Prime Bold.ttf"),
        url("../Fonts/Courier\ Prime\ Bold.ttf") format("truetype");
    }
  
  * {
    font-family: "Prime";
  }

  
  
  .sophieAgenda {
    filter: opacity(50%);
    -webkit-filter: opacity(60%);
  }




  

  .divSophieAgendaSecond span{
    font-family: "Prime Bold";
    color: #c5a28a;
  }

  @keyframes showup {
    0% {opacity:0;}
    20% {opacity:0,5;}
    80% {opacity:0,5;}
    100% {opacity:1;}
}

@keyframes reveal {
    0% {opacity:0;}
    20% {opacity:0,3;}
    80% {opacity:0,5;}
    100% {opacity:1;}
}

.profilecontentframe {
    margin: auto;
    background: #fcf3ed;
    /* box-shadow: 20px 20px 41px #f4d8c4, -20px -20px 41px #fcf3ed; */
    /* width: 100%; */
    /* height: 100vh; */
    background-color: #fcf3ed;
    /* padding: 20px; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
  }

  .leaflet-control-attribution{
    visibility: hidden;
  }

  @media screen and (max-width: 600px) {
    .leaflet-container{
        width: 300px;
        height: 280px;
        border-radius: 20px;
        margin-top: 20px;
      }
      .divSophieAgendaSecond { 
        /* font-family: "Prime Bold"; */
        font-size: 15px;
        display:inline-block;
        overflow:hidden;
        white-space:wrap;
        margin: 5px 10% 5px 10%;
        text-align: center;
        animation: reveal 2s 1;
      }
      .containerImgSophieAgenda {
        width: 100%;
        height: 300px;
        overflow: hidden;
        position: relative;
      }

      .containerImgSophieAgendaRdv{
        width: 100%;
        height: 150px;
        overflow: hidden;
        position: relative;
      }
      
      .sophieAgenda {
        width: 100%;
        height: 300px;
        position: absolute;
      }
      .sophieAgendaRdv{
        width: 100%;
        height: 150px;
        position: absolute;
      }
      .divSophieAgenda {
        display:inline-block;
        overflow:hidden;
        white-space:wrap;
        font-size: 30px;
        margin-bottom: 10px;
        animation: showup 5s 1;
      }
      .divSophieAgendaRdv {
        display:inline-block;
        overflow:hidden;
        white-space:wrap;
        font-size: 25px;
        margin-bottom: 10px;
        animation: showup 5s 1;
      }
      .textSophieAgenda{
        position: relative;
        overflow:hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
    }
  }

  @media screen and (min-width: 600px) {
    .leaflet-container{
        width: 400px;
        height: 400px;
        border-radius: 20px;
        margin-top: 20px;
      }
      .divSophieAgendaSecond { 
        /* font-family: "Prime Bold"; */
        font-size: 20px;
        display:inline-block;
        overflow:hidden;
        white-space:wrap;
        margin: 20px 20% 20px 20%;
        text-align: center;
        animation: reveal 2s 1;
      }
      .containerImgSophieAgenda {
        width: 100%;
        height: 500px;
        overflow: hidden;
        position: relative;
      }
      .containerImgSophieAgendaRdv{
        width: 100%;
        height: 300px;
        overflow: hidden;
        position: relative;
      }
      
      .sophieAgenda {
        width: 100%;
        height: 500px;
        position: absolute;
      }
      .sophieAgendaRdv{
        width: 100%;
        height: 300px;
        position: absolute;
      }
      .divSophieAgendaRdv {
        display:inline-block;
        overflow:hidden;
        white-space:wrap;
        font-size: 30px;
        margin-bottom: 10px;
        animation: showup 5s 1;
      }
      .divSophieAgenda {
        display:inline-block;
        overflow:hidden;
        white-space:wrap;
        font-size: 30px;
        margin-bottom: 30px;
        animation: showup 5s 1;
      }
      .textSophieAgenda{
        position: relative;
        overflow:hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
    }
  }


  .leaflet-div-icon {
    background: transparent;
    border: none;
  }

  .profilecontentframe h4{
    color: #c5a28a;
  }

  .inputContact{
    border-radius: 5px;
    margin: 5px;
    padding: 3px;
    border: none;
    text-align: center;
  }

  .inputContactMessage{
    border-radius: 5px;
    margin: 5px;
    padding: 3px;
    width: 50%;
    height: 100px;
    border: none;

  }